import "./Home.css";
import React, { Component } from "react";
import { MDBAvatar, MDBLightbox, MDBContainer, MDBRow, MDBCol } from "mdbreact";

class Home extends Component {
  state = {
    isOpen: false,
    heading: [
      {
        src: "https://faithconnect.s3.us-east-2.amazonaws.com/images/FaithConnect.png",
        description: (
          <a href="https://www.faithconnect.com">FaithConnect.com</a>
        ),
      },
      {
        src: "https://faithconnect.s3.us-east-2.amazonaws.com/images/usmIphone.png",
        description: (
          <a href="https://github.com/cristian-custodio/iOS-App-University">
            USM iOS App
          </a>
        ),
      },
      {
        src: "https://faithconnect.s3.us-east-2.amazonaws.com/images/usmAndroid.png",
        description: (
          <a href="https://github.com/cristian-custodio/Android-App-University">
            USM Android App
          </a>
        ),
      },
      {
        src: "https://faithconnect.s3.us-east-2.amazonaws.com/merchant-pricing-solution-project.png",
        description: (
          <a href="https://www.merchantpricingsolutions.com">
            Merchant Pricing Solutions
          </a>
        ),
      },
    ],
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "/home.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="developer">
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
            <div className="container">
              <a className="navbar-brand" href="#">
                Portfolio
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo02"
              >
                <ul className="navbar-nav mr-auto smooth-scroll">
                  <li className="nav-item">
                    <a className="nav-link" href="#home">
                      Home <span className="sr-only">(current)</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#about" data-offset="100">
                      About
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#skills" data-offset="100">
                      Skills
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#works" data-offset="100">
                      Works
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#contact" data-offset="100">
                      Contact
                    </a>
                  </li>
                </ul>

                <ul className="navbar-nav nav-flex-icons">
                  <li className="nav-item">
                    <a
                      target="+blank"
                      href="https://www.linkedin.com/in/cristian-custodio/"
                      className="nav-link"
                    >
                      <i className="fab fa-linkedin light-green-text-2"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div
            id="home"
            className="view jarallax"
            // data-jarallax='{"speed": 0.2}'
            data-speed="0.5"
            style={{
              backgroundImage:
                "url('https://mdbootstrap.com/img/Photos/Others/forest1.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="mask rgba-white-strong">
              <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="row smooth-scroll">
                  <div className="col-md-12 pt-3">
                    <div className="white-text text-center pt-5">
                      <h1 className="display-2 mb-4 dark-grey-text wow fadeIn">
                        I am <strong>Cristian Custodio</strong>
                      </h1>

                      <h5
                        className="text-uppercase font-weight-bold wow fadeIn"
                        data-wow-delay="0.4s"
                      >
                        <mark>Full Stack Web developer</mark>
                      </h5>

                      <a
                        href="#about"
                        className="btn btn-floating btn-large wow fadeIn"
                        data-wow-delay="0.4s"
                        data-offset="100"
                      >
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="container">
            <section id="about" className="section feature-box mb-5">
              <h2
                className="text-center text-uppercase my-5 pt-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                <MDBAvatar
                  tag="img"
                  src="images/cristian.png"
                  style={{ maxWidth: "200px" }}
                  className="rounded-circle z-depth-1 img-fluid m-3"
                  alt="Sample avatar"
                />
                About <strong>me</strong>
              </h2>

              <p
                className="text-center w-responsive mx-auto wow fadeIn my-5"
                data-wow-delay="0.2s"
              >
                Bilingual Senior Software Engineering professional having over
                10 years of experience in IT, which include 5 years of combined
                experience in Web Development (ASP.NET, MERN Stack) and Mobile
                (iOS & Android) development, with Heroku, Google PlayStore and
                Apple App store deployment.
              </p>

              <div
                className="row features-big text-center wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div className="col-md-4 mb-5">
                  <div className="card card-body hoverable">
                    <i
                      className="fas fa-laptop fa-3x mb-4"
                      aria-hidden="true"
                    ></i>

                    <h5 className="font-weight-bold text-uppercase mb-4">
                      Freelancer
                    </h5>

                    <p className="dark-grey-text">
                      I love developing web applications that will improve your
                      business operations and improve your workflow. Lets build
                      something great!
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-5">
                  <div className="card card-body yellow hoverable">
                    <i
                      className="fas fa-code fa-3x mb-4"
                      aria-hidden="true"
                    ></i>

                    <h5 className="font-weight-bold text-uppercase mb-4">
                      Developer
                    </h5>

                    <p className="dark-grey-text">
                      I am a passionate backend developer who loves technologies
                      such as Node, Express, Passport, Mongo, MySQL, Handlebars,
                      NPM, YARN
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-5">
                  <div className="card card-body hoverable">
                    <i
                      className="fas fa-pencil-alt fa-3x mb-4"
                      aria-hidden="true"
                    ></i>

                    <h5 className="font-weight-bold text-uppercase mb-4">
                      Designer
                    </h5>

                    <p className="dark-grey-text">
                      Front End development allows me to express my passion for
                      creativity. I enjoy using React and MDBBoostrap, and
                      Photoshop. Lets design something beatiful!
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div
            className="container-fluid"
            style={{ backgroundColor: "#f3f3f5" }}
          >
            <div className="container py-4 pt-4">
              <section id="skills">
                <div className="row py-5">
                  <div
                    className="col-lg-6 col-md-12 mb-3 wow fadeIn"
                    data-wow-delay="0.4s"
                  >
                    <div className="d-flex justify-content-start">
                      <h4
                        className="text-center text-uppercase mb-5 pb-3 mt-4 wow fadeIn"
                        data-wow-delay="0.2s"
                      >
                        My
                        <strong> experience</strong>
                      </h4>
                    </div>

                    <blockquote className="blockquote bq-warning mb-4">
                      <div className="row">
                        {" "}
                        <i
                          className="fas fa-briefcase fa-x mb-1 mr-3 ml-3"
                          aria-hidden="true"
                        ></i>
                        <h5 className="font-weight-bold mb-3">
                          Endtime Investments, LLC - Web Developer
                        </h5>
                      </div>

                      <p className="font-weight-bold ml-1 dark-grey-text mb-2">
                        July, 2017 - Present (5 years)
                      </p>

                      <p className="mb-0 ml-1 light-grey-text">
                        Full-Stack developer role where I worked with clients to
                        develop their web applications, websites, Mobile
                        Applications, dashboards, and custom applications. I
                        also employed coding practices based on commonly
                        accepted standards to establish site layout and user
                        interface.
                      </p>
                    </blockquote>

                    <blockquote className="blockquote bq-warning mt-1 mb-4">
                      <div className="row">
                        {" "}
                        <i
                          className="fas fa-briefcase fa-x mb-1 mr-3 ml-3"
                          aria-hidden="true"
                        ></i>
                        <h5 className="font-weight-bold mb-3">
                          ClearSale, LLC - Software Engineer (ASP.net)
                        </h5>
                      </div>

                      <p className="font-weight-bold ml-1 dark-grey-text mb-2">
                        June 2018 - November 2019 (1 year, 6 Month)
                      </p>

                      <p className="mb-0 ml-1 light-grey-text">
                        ASP.NET Software engineer position where I developed
                        several C# APIs to be consumed internally. I also was
                        part of the team who developed a full-fledged web-based
                        IT dashboard to support our client base with features
                        such as notification options, user activations, webhook
                        configuration, and order information.
                      </p>
                    </blockquote>

                    <blockquote className="blockquote bq-warning">
                      <div className="row">
                        {" "}
                        <i
                          className="fas fa-briefcase fa-x mb-1 mr-3 ml-3"
                          aria-hidden="true"
                        ></i>
                        <h5 className="font-weight-bold mb-3">
                          edX - Teaching Assistant - Dev Support (Web)
                        </h5>
                      </div>

                      <p className="font-weight-bold ml-1 dark-grey-text mb-2">
                        <strong>November 2019 - Present (3 Years)</strong>
                      </p>

                      <p className="mb-0 ml-1 light-grey-text">
                        Full-Stack developer tutoring role where I demonstrated
                        success teaching in a classroom setting for JavaScript,
                        HTML5 / CSS3, jQuery, Node.js, React.js, GraphQL, Redux
                        Git, Command Lines, MySQL, and Sequelize.
                      </p>
                    </blockquote>
                  </div>

                  <div
                    className="col-lg-5 offset-lg-1 col-md-12 mb-4 wow fadeIn"
                    data-wow-delay="0.4s"
                  >
                    <div className="d-flex justify-content-start">
                      <h4
                        className="text-center text-uppercase mb-5 pb-3 mt-4 wow fadeIn"
                        data-wow-delay="0.2s"
                      >
                        Development
                        <strong> Skills</strong>
                      </h4>
                    </div>

                    <p
                      className="black-text text-uppercase font-weight-bold"
                      align="justify"
                    >
                      JavaScript
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>

                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      React
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>

                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      Graphql
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>

                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      Redux
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>

                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      Node
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      MDBootstrap
                    </p>

                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p
                      className="black-text text-uppercase font-weight-bold pt-3"
                      align="justify"
                    >
                      AWS & GCP
                    </p>
                    <div className="md-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="container">
            <section className="section feature-box my-5 pb-5">
              <h2
                className="text-center text-uppercase my-5 pt-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                Process of <strong>creating</strong>
              </h2>

              <p
                className="text-center w-responsive mx-auto wow fadeIn my-5"
                data-wow-delay="0.2s"
              >
                {" "}
                I enjoy taking the time and gathering the requirements which
                will satisfy your business desires. Lets build something
                customized, and specifically for what you need :)
              </p>

              <ul className="nav md-tabs nav-justified blue" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#panel1"
                    role="tab"
                  >
                    01. Research
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#panel2"
                    role="tab"
                  >
                    02. Design
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#panel3"
                    role="tab"
                  >
                    03. Development
                  </a>
                </li>
              </ul>

              <div className="tab-content card">
                <div
                  className="tab-pane fade in show active"
                  id="panel1"
                  role="tabpanel"
                >
                  <br></br>

                  <div className="row mt-2">
                    <div className="col-2 text-center">
                      {" "}
                      <i
                        className="fas fa-search fa-3x mb-1 grey-text"
                        aria-hidden="true"
                      ></i>
                    </div>

                    <div className="col-9 mb-2">
                      <p className="dark-grey-text">
                        The most important part. A very crucial step in
                        gathering and researching the information needed to
                        create a useful product.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="panel2" role="tabpanel">
                  <br />

                  <div className="row mt-2">
                    <div className="col-2 text-center">
                      {" "}
                      <i
                        className="fas fa-pencil-alt fa-3x mb-1 grey-text"
                        aria-hidden="true"
                      ></i>
                    </div>

                    <div className="col-9 mb-2">
                      <p className="dark-grey-text">
                        This is the part where our creativity comes to life. I
                        love to include the client or customer in the design
                        process so that they can help me make somehting they
                        will be proud to call theirs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="panel3" role="tabpanel">
                  <br />

                  <div className="row mt-2">
                    <div className="col-2 text-center">
                      {" "}
                      <i
                        className="fas fa-code fa-3x mb-1 grey-text"
                        aria-hidden="true"
                      ></i>{" "}
                    </div>

                    <div className="col-9 mb-2">
                      <p className="dark-grey-text">
                        This where everything that I have learn comes together.
                        Time to build it and deploy it!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div
            className="streak streak-photo streak-md"
            style={{
              backgroundImage:
                "url('https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20%2811%29.jpg",
            }}
          >
            <div className="mask flex-center rgba-indigo-strong">
              <div className="white-text smooth-scroll mx-4">
                <h2 className="h2-responsive wow fadeIn mb-5">
                  <i className="fas fa-quote-left" aria-hidden="true"></i>{" "}
                  Design is not just what it looks like and feels like. Design
                  is how it works.{" "}
                  <i className="fas fa-quote-right" aria-hidden="true"></i>
                </h2>

                <h5
                  className="text-center font-italic wow fadeIn"
                  data-wow-delay="0.2s"
                >
                  ~ Steve Jobs
                </h5>
              </div>
            </div>
          </div>

          <div className="container">
            <section id="works" className="section mb-5">
              <h2
                className="text-center text-uppercase my-5 pt-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                My <strong> Development projects</strong>
              </h2>

              <p className="text-center w-responsive mx-auto wow fadeIn my-5">
                From FrontEnd to Backend to UI/UX Design, I enjoy learning new
                technologies and continously improving.
              </p>

              <div className="row wow fadeIn" data-wow-debilay="0.4s">
                <div className="col-md-12 mb-5">
                  <div id="mdb-lightbox-ui"></div>
                  <MDBContainer>
                    <MDBLightbox md="4" images={this.state.heading} noMargins />
                  </MDBContainer>{" "}
                </div>
              </div>
            </section>

            <hr />
          </div>

          <div id="contact" className="container">
            <section className="section contact-section mt-4 mb-5">
              <h2
                className="text-center text-uppercase my-5 pt-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                Contact <strong>me</strong>
              </h2>

              <p
                className="text-center w-responsive mx-auto mb-5 pb-4 wow fadeIn"
                data-wow-delay="0.2s"
              >
                Have an idea for a software product? Lets get together and build
                it!{" "}
              </p>

              <div className="row wow fadeIn" data-wow-delay="0.4s">
                <div className="col-md-4">
                  <ul className="contact-icons list-unstyled text-center">
                    <li>
                      <i className="fas fa-map-marker-alt fa-2x"></i>

                      <p>Dallas, TX 75104, USA</p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="contact-icons list-unstyled text-center">
                    <li>
                      <i className="fas fa-phone fa-2x"></i>

                      <p>+ (786)828-0029</p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="contact-icons list-unstyled text-center">
                    <li>
                      <i className="fas fa-envelope fa-2x"></i>

                      <p>cristian@cristiancustodio.com</p>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="streak1">
            <div className="flex-center">
              <ul className="list-unstyled">
                <li>
                  <h2 className="h2-responsive mt-5 wow fadeIn">
                    Want an outstanding project?
                  </h2>
                </li>

                <li>
                  <h5
                    className="h5-responsive wow fadeIn"
                    data-wow-delay="0.2s"
                  >
                    Just send me a message!
                  </h5>
                </li>
              </ul>
            </div>
          </div>
        </main>
        <div className="dotted-scrollspy clearfix d-none d-sm-block">
          <ul className="nav smooth-scroll flex-column">
            <li className="nav-item">
              <a className="nav-link" href="#home">
                <span></span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#about">
                <span></span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#skills">
                <span></span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#works">
                <span></span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#contact">
                <span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Home;
